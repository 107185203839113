import { useState } from "react";
import bichitos from "../../assets/bichito.svg";
import { Modal, Button } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { useMediaQuery } from "react-responsive";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

const Hero = () => {
  const [bichito, setBichito] = useState(false);
  const [gplay, setGplay] = useState(false);
  const [store, setStore] = useState(false);
  const [show, setShow] = useState(false);
  const [image, setImage] = useState(null);

  const vistaMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const handleShow = () => setShow(true);

  const ScrollToben = () => {
    window.scrollTo({
      top: window.innerHeight - 200,
      behavior: "smooth",
    });
  };

  const locale = {
    noResultsText: "No existen resultados",
    placeholder: "",
    searchPlaceholder: "Buscar...",
    checkAll: "",
    yesterday: "Ayer",
    today: "Hoy",
    dateLocale: "es_AR",
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm({
    mode: "onChange",
  });

  const handleClose = () => {
    setShow(false);

    reset();
  };

  const [showEnviado, setShowEnviado] = useState(false);
  const handleCloseEnviado = () => {
    setShowEnviado(false);

    reset();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const baseUrl = process.env.REACT_APP_BASEURL;
  const port = process.env.REACT_APP_PORT;

  const onSubmit = (data) => {
    axios
      .post(
        `${baseUrl}/emailSender/reporte`,
        (data = { ...data, image: image })
      )
      .then(function (response) {
        console.log(response.data);
        setShow(false);
        setShowEnviado(true);
        reset();
      })
      .catch(function (error) {
        console.log(error);
        setShowEnviado(false);
      });
  };

  return (
    <section className="beneficios-hero">
      {/* BLACK NAVIDAD */}
      {/* <div className="xs:w-[30%] lt:w-[25%]  flex flex-col relative items-center justify-center">
           <img
             className="mb-6 w-[80%] xs:w-auto"
             src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1703259824/WALLET%20BLACK/Slider/naviBlack_xlkrbh.svg"
           />
           <a
             href="/beneficios"
             className="container-button flex justify-center z-[1000] w-[60%] md:w-[35%]"
           >
             Ver Beneficios
           </a>
         </div>
         <img
           className="absolute scale-[3.5] xs:scale-[2.4] sm:scale-[2] lt:scale-[1.9] bottom-[-10%] xs:bottom-[-45%] sm:bottom-[-75%] lt:bottom-[-90%]  md:bottom-[-63%] right-[-35%] xs:right-[-28%]"
           src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1703261716/WALLET%20BLACK/Slider/navidad-venus_1_njl5hl.png"
         />
         
         <img
           className="absolute w-[50%] xs:w-[35%] sm:w-[30%] lt:w-auto bottom-[-3%] left-[1%] xs:left-[5%] lt:left-[10%] md:left-[15%]"
           src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1703261564/WALLET%20BLACK/Slider/Group_575_gqzgdc.png"
         /> */}
      {/* BLACK NAVIDAD */}
      <div className="se:h-[70vh] spro:h-[55vh]  relative xs:static xs:h-[75vh] flex items-center justify-center">
        {vistaMobile ? (
          <img
            src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_25/f_auto/v1694452871/WALLET%20BLACK/Group_551_7_brlp5s.png"
            className="absolute w-full top-[-2%] h-full z-[-10]"
            alt="BG img"
            loading="lazy"
            decoding="async"
            width={392}
            height={758}
          />
        ) : (
          <img
            src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_25/f_auto/v1694437573/WALLET%20BLACK/Header_cquwga.png"
            className="absolute z-[-10] xs:top-[0%] lt:top-[-13%]"
            alt="BG img"
            loading="lazy"
            decoding="async"
            width={1920}
            height={1222}
          />
        )}

        <div className="flex items-center flex-col mb-[5%]">
          <img
            src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1694442300/WALLET%20BLACK/SER_BLACKER_jffu0o.svg"
            className="se:w-[65%] spro:w-[70%] xs:w-auto"
            alt="Ser Black"
            loading="lazy"
            decoding="async"
            width={622}
            height={166}
          />

          <div className="flex flex-col xs:flex-row items-center justify-center  mt-[3%]">
            <h3 className="se:text-[15px] spro:text-[18px] font-[500] xs:mt-2 xs:text-[33px] text-white ">
              tiene sus beneficios,descubrilos
            </h3>
            <img
              src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1694442840/WALLET%20BLACK/flechita_iz7uj9.svg"
              className="w-[15%] cursor-pointer duration-300 hover:translate-y-2 xs:w-auto mt-3 xs:mt-0 xs:ml-3"
              onClick={() => ScrollToben()}
              alt="Arrow"
              loading="lazy"
              decoding="async"
              width={30}
              height={30}
            />
          </div>

          {/* BARRA DE DESCARGA */}
          <div className="flex flex-col items-center space-y-3 fixed z-[200] right-[5%] bottom-[5%] xs:bottom-[10%]">
            <button id="playstore">
              <a
                id="playstore"
                href={
                  vistaMobile
                    ? "https://plataforma.blackwallet.com.ar/landing/share.html"
                    : "https://play.google.com/store/apps/details?id=app.blackwallet.ar&hl=es_419"
                }
                target="blank"
              >
                <img
                  onMouseEnter={vistaMobile ? null : () => setGplay(true)}
                  onMouseLeave={vistaMobile ? null : () => setGplay(false)}
                  src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1694461315/WALLET%20BLACK/gplay_prxmh8.svg"
                  className="w-10"
                  alt="Play Store"
                  loading="lazy"
                  decoding="async"
                  width={40}
                  height={40}
                />
              </a>
            </button>
            {gplay && (
              <div className="fixed z-[200] text-[14px] bg-white right-[8.5%] bottom-[10%] xs:bottom-[27.5%] md:bottom-[23.5%] p-[0.5rem] rounded-md">
                Descargá la app
              </div>
            )}

            <button id="appstore">
              <a
                id="appstore"
                href={
                  vistaMobile
                    ? "https://plataforma.blackwallet.com.ar/landing/share.html"
                    : "https://apps.apple.com/ph/app/black-wallet/id1661314614?uo=2"
                }
                target="blank"
              >
                <img
                  onMouseEnter={vistaMobile ? null : () => setStore(true)}
                  onMouseLeave={vistaMobile ? null : () => setStore(false)}
                  src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1694461315/WALLET%20BLACK/appstore_v1o51r.svg"
                  className="w-12"
                  alt="Apple Store"
                  loading="lazy"
                  decoding="async"
                  width={48}
                  height={48}
                />
              </a>
            </button>
            {store && (
              <div className="fixed z-[200] text-[14px] bg-white right-[8.5%] bottom-[7%] xs:bottom-[20%] md:bottom-[17.5%] p-[0.5rem] rounded-md">
                Descargá la app
              </div>
            )}

            <button onClick={handleShow}>
              <img
                src={bichitos}
                alt="bichito"
                onMouseEnter={() => setBichito(true)}
                onMouseLeave={() => setBichito(false)}
                className="z-[200] w-10 xs:w-14"
                loading="lazy"
                decoding="async"
                width={56}
                height={56}
              />
              {bichito && (
                <div className="fixed z-[200] text-[14px] bg-white right-[8.5%] bottom-[10%] xs:bottom-[10.8%] p-[0.7rem] rounded-md">
                  Reportá un error en la app
                </div>
              )}
            </button>
          </div>
          {/* FIN BARRA DE DESCARGA */}
        </div>
      </div>

      {/* POPUP FORM */}
      <Modal
        show={show}
        backdrop="static"
        onHide={handleClose}
        size="lg"
        centered
        closeButton
        className="form-step "
      >
        <Modal.Body id="reporte_error">
          <div>
            <button className="boton-cerrar z-[200]" onClick={handleClose}>
              x
            </button>
            <h1 className="tittle-web font-bold se:text-[1.6em]  xs:text-[2.5em]">
              Reportá un error
            </h1>
            <p className="text-web">
              Completá el formulario para que podamos ayudarte
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={
                vistaMobile ? "container-col-form-step" : "container-form-step"
              }
            >
              <div>
                <Controller
                  name="Mail"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      locale={locale}
                      inputRef={ref}
                      className="input-login"
                      autoComplete="no"
                      {...register("Mail", {
                        required: true,
                      })}
                      id="outlined-basic"
                      label="Mail"
                      variant="outlined"
                    />
                  )}
                />
                {errors.Mail && <p className="error">Ingrese su email</p>}
              </div>
            </div>

            <div className="container-form-step">
              <div>
                <Controller
                  name="dispositivo"
                  control={control}
                  //rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      locale={locale}
                      inputRef={ref}
                      className="input-login"
                      autoComplete="no"
                      {...register("dispositivo", {
                        required: true,
                      })}
                      id="outlined-basic"
                      label="Modelo de tu dispositivo"
                      variant="outlined"
                    />
                  )}
                />
                {errors.dispositivo && (
                  <p className="error">Ingrese el modelo de su dispositivo</p>
                )}
              </div>
            </div>

            <div className="container-form-step">
              <div>
                <Controller
                  name="version"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      locale={locale}
                      inputRef={ref}
                      className="input-login"
                      autoComplete="no"
                      {...register("version", {
                        required: true,
                      })}
                      id="outlined-basic"
                      label={"Versión de app"}
                      variant="outlined"
                    />
                  )}
                />
                {errors.version && (
                  <p className="error">Ingrese la versión de app</p>
                )}
              </div>

              <div className="container-form-step">
                <div>
                  <Controller
                    name="sistema"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { ref, ...field } }) => (
                      <select
                        {...field}
                        locale={locale}
                        className="input-login p-[1.1rem] rounded-[0.60rem] border w-full bg-black"
                        autoComplete="no"
                        {...register("sistema", {
                          required: true,
                        })}
                        id="outlined-basic"
                        label="Sistema operativo"
                        variant="outlined"
                        defaultValue={""}
                      >
                        <option
                          className="text-gray-400"
                          disabled={true}
                          value=""
                        >
                          Seleccioná un sistema operativo
                        </option>
                        <option value="Android">Android</option>
                        <option value="iOS">iOS</option>
                      </select>
                    )}
                  />
                  {errors.sistema && (
                    <p className="error">Seleccione un sistema operativo</p>
                  )}
                </div>
              </div>
            </div>

            <div className="container-form-step  flex flex-col relative">
              <input
                name="file"
                id="file"
                className="inputfile"
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="fileInput" className="custom-file-label rounded-xl">
                Arrastrá o buscá capturas de pantalla del error
              </label>
              <h5 className="w-full items-start text-white pl-4 text-[9px]">
                Imágenes (.jpg o .png) de hasta 5MB
              </h5>
            </div>

            {image ? (
              <div className="flex flex-row">
                <div className="w-[20%] border-2 h-[8vh]">
                  <img
                    className="w-full object-contain h-full"
                    src={image}
                    alt="Bug screenshot"
                  />
                </div>
                <button
                  className="text-white font-ubuntu"
                  onClick={() => setImage("")}
                >
                  X
                </button>
              </div>
            ) : (
              ""
            )}

            <div className="container-form-step">
              <div>
                <Controller
                  name="notas"
                  control={control}
                  //rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <input
                      {...field}
                      locale={locale}
                      inputRef={ref}
                      placeholder="Contanos más sobre el error"
                      className="input-login pb-[3rem] pl-[1rem] pt-2 rounded-[0.60rem] border w-full bg-black"
                      autoComplete="no"
                      {...register("notas", {
                        required: true,
                      })}
                      id="outlined-basic"
                      label="Notas o aclaraciones"
                      variant="outlined"
                    />
                  )}
                />
                {errors.notas && (
                  <p className="error">Ingrese una nota o aclaracion</p>
                )}
              </div>
            </div>

            <div className="container-button-step">
              <Button className="container-button" type="submit">
                Enviar
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEnviado}
        backdrop="static"
        onHide={handleCloseEnviado}
        size="lg"
        centered
        closeButton
        className="form-step"
      >
        <Modal.Body className="modal_repote" id="Reporte_enviado">
          <div className="check-form-enviado" id="Reporte_enviado">
            <button className="boton-cerrar" onClick={handleCloseEnviado}>
              x
            </button>
            <img
              width={70}
              src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1674834976/WALLET%20BLACK/check-enviado_io9ejl.png"
              alt="Check"
              loading="lazy"
            />
            <h1
              id="Reporte_enviado"
              className="modal_reporte tittle-web se:text-[2em] xs:text-[2.5em]"
            >
              Reportaste el error
            </h1>
            <p className="text-web w-[80%] xs:w-[40%]">
              Vamos a revisar la informacion y buscar una solucion.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Hero;
