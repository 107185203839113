import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Modal, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { LazyVideo } from "../../Components/Lozad/Lozad";

const Hero = () => {
  const baseUrl = process.env.REACT_APP_BASEURL;
  const port = process.env.REACT_APP_PORT;

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    reset();
  };
  const handleShow = () => setShow(true);

  const [showEnviado, setShowEnviado] = useState(false);
  const handleCloseEnviado = () => {
    setShowEnviado(false);
    reset();
  };

  const vistaMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const locale = {
    noResultsText: "No existen resultados",
    placeholder: "",
    searchPlaceholder: "Buscar...",
    checkAll: "",
    yesterday: "Ayer",
    today: "Hoy",
    dateLocale: "es_AR",
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    await axios
      .post(`${baseUrl}/emailSender/comerciantes`, data)
      .then(function (response) {
        console.log(response.data);
        setShow(false);
        setShowEnviado(true);
        reset();
      })
      .catch(function (error) {
        console.log(error);
        setShowEnviado(false);
      });
  };

  return (
    <section className="container-nosotros font-ubuntu">
      <div className="container-flex-row tam-section-lg gradient-section reverse-row">
        <div className="child-container-flex-row">
          <div className="child-container-text">
            <h1 className="tittle-web font-ubuntu">
              La oportunidad de <br /> crecer para tu negocio.
            </h1>
            <p className="text-web">
              Pensamos un conjunto de beneficios para apoyar integralmente el
              despegue de tu negocio.
            </p>
            <p className="text-web">
              Queremos que conozcas todo lo que tenemos para ofrecerte, abrí tu
              cuenta en Black.
            </p>
            <div className="container-botones">
              <button className="container-button" onClick={handleShow}>
                {" "}
                Abrí tu cuenta{" "}
              </button>
              <a href="https://plataforma.blackwallet.com.ar/">
                <button className="container-button-border"> Ingresar </button>
              </a>
            </div>
          </div>
          {vistaMobile ? (
            <div
              className={"child-container-media"}
            >
              <LazyVideo
                src={"https://res.cloudinary.com/dc1bgmjr9/video/upload/q_25/v1675884563/WALLET%20BLACK/Videos/Quienes_Somos_Animaciones_ac3pvh.mp4"}
                type={'video/mp4'}
                poster={"https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1676036804/WALLET%20BLACK/Videos/Posters/quienes_somos__mobile_effgg8.png"}
                width={500}
                height={500}
              />
            </div>
          ) : (
            <div className="child-container-media">
              <video width={750} autoPlay muted loop>
                <source src="https://res.cloudinary.com/dc1bgmjr9/video/upload/q_50/v1672839588/WALLET%20BLACK/quienes_somos_animaciones_1_gwnm8p.webm"></source>
              </video>
            </div>
          )}
        </div>
      </div>
      
      {/* POPUPS */}
      <Modal
        show={show}
        backdrop="static"
        onHide={handleClose}
        size="lg"
        centered
        closeButton
        className="form-step"
      >
        <Modal.Body>
          <div>
            <button className="boton-cerrar" onClick={handleClose}>
              x
            </button>
            <h1 className="tittle-web font-bold se:text-[1.6em] mb-2 xs:text-[2.5em]">
              Solicitá tu adhesión
            </h1>
            <p className="text-web">
              Completá el formulario con tus datos y los de tu comercio.
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <h5 className="font-[500] text-[1.2em]" style={{ color: "white" }}>
              Tus datos
            </h5>
            <div
              className={
                vistaMobile ? "container-col-form-step" : "container-form-step"
              }
            >
              <div>
                <Controller
                  name="nombre"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      locale={locale}
                      inputRef={ref}
                      className="input-login"
                      autoComplete="off"
                      {...register("nombre", {
                        required: true,
                      })}
                      id="outlined-basic"
                      label="Nombre/s"
                      variant="outlined"
                    />
                  )}
                />
                {errors.nombre && <p className="error">Ingrese un nombre.</p>}
              </div>
              <div>
                <Controller
                  name="apellido"
                  control={control}
                  //rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      locale={locale}
                      inputRef={ref}
                      className="input-login"
                      autoComplete="off"
                      {...register("apellido", {
                        required: true,
                      })}
                      id="outlined-basic"
                      label="Apellido/s"
                      variant="outlined"
                    />
                  )}
                />
                {errors.apellido && (
                  <p className="error">Ingrese un apellido.</p>
                )}
              </div>
            </div>

            <div className="container-form-step">
              <div>
                <Controller
                  name="CUIT"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      locale={locale}
                      inputRef={ref}
                      className="input-login"
                      type="tel"
                      autoComplete="off"
                      {...register("CUIT", {
                        required: true,
                        maxLength: 11,
                        minLength: 10,
                      })}
                      id="outlined-basic"
                      label="CUIT/CUIL"
                      variant="outlined"
                      inputProps={{ maxLength: 11, minLength: 10 }}
                    />
                  )}
                />
                {errors.CUIT?.type === "required" && (
                  <p className="error">Ingrese su CUIL/CUIT.</p>
                )}
                {errors.CUIT?.type === "maxLength" && (
                  <p className="error">Verifique su número de CUIT/CUIL</p>
                )}
                {errors.CUIT?.type === "minLength" && (
                  <p className="error">Verifique su número de CUIT/CUIL</p>
                )}
              </div>
              <div>
                <Controller
                  name="comercio"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      locale={locale}
                      inputRef={ref}
                      className="input-login"
                      autoComplete="off"
                      {...register("comercio", {
                        required: true,
                        maxLength: 18,
                      })}
                      id="outlined-basic"
                      label="Nombre del comercio"
                      variant="outlined"
                    />
                  )}
                />
                {errors.comercio && (
                  <p className="error">Ingrese el nombre de su comercio.</p>
                )}
              </div>
            </div>

            <div className="container-form-step mt-2">
              <div>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      locale={locale}
                      inputRef={ref}
                      className="input-login"
                      autoComplete="off"
                      type="email"
                      {...register("email", {
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                      id="outlined-basic"
                      label="Mail"
                      variant="outlined"
                    />
                  )}
                />
                {errors.email && <p className="error">Ingrese un email.</p>}
              </div>
            </div>

            <div className="container-button-step">
              <Button
                className="container-button"
                type="submit"
                data-gtm-ignore
              >
                Enviar
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEnviado}
        backdrop="static"
        onHide={handleCloseEnviado}
        size="lg"
        centered
        closeButton
        className="form-step"
      >
        <Modal.Body class="solicitud_enviada" id="Solicitud_enviada">
          <div className="check-form-enviado" id="Solicitud_enviada">
            <button className="boton-cerrar" onClick={handleCloseEnviado}>
              x
            </button>
            <img
              width={70}
              src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1674834976/WALLET%20BLACK/check-enviado_io9ejl.png"
              alt="check"
              loading="lazy"
            />
            <h1
              class="solicitud_enviada"
              id="Solicitud_enviadaH"
              className="tittle-web text-[2em] xs:text-[2.6em]"
            >
              Solicitud enviada
            </h1>
            <p className="text-web">
              Vamos a validar tus datos y a la brevedad nos pondremos en
              contacto.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Hero;
