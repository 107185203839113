import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Modal, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import axios from "axios";
import bichitos from "../../assets/bichito.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { LazyVideo } from "../../Components/Lozad/Lozad";

const Hero = () => {
  const baseUrl = process.env.REACT_APP_BASEURL;
  const port = process.env.REACT_APP_PORT;
  const [show, setShow] = useState(false);
  const [gplay, setGplay] = useState(false);
  const [bichito, setBichito] = useState(false);
  const [store, setStore] = useState(false);
  const [image, setImage] = useState(null);

  const locale = {
    noResultsText: "No existen resultados",
    placeholder: "",
    searchPlaceholder: "Buscar...",
    checkAll: "",
    yesterday: "Ayer",
    today: "Hoy",
    dateLocale: "es_AR",
  };

  const handleShow = () => setShow(true);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const CustomDot = ({ onClick, ...rest }) => {
    const {
      active,
    } = rest;
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
      <button
        className={
          active
            ? "bg-[#FFFFFF] w-[0.6rem] h-[0.6rem] rounded-full mt-36 xs:mt-0"
            : "bg-[#c5c4c4] w-[0.6rem] mt-36  h-[0.6rem] rounded-full"
        }
        onClick={() => onClick()}
        style={{ margin: "0 5px" }}
      ></button>
    );
  };

  const vistaMobile = useMediaQuery({ query: "(max-width: 787px)" });

  const carouselItems = [
    // <>
    // {vistaMobile ?  <div className="height-bloque-hero-home slide4  w-full flex flex-col justify-center absolute bg-black">
    // <div className="flex flex-col items-center">
    //         <p className="text-[#FFFFFF] text-[13px] font-[300] mb-1 spro:mb-2">17 AL 24 DE NOVIEMBRE</p>
    //         <div className="flex flex-col leading-[1rem] font-ubuntu items-center">
    //         <p className="se:text-[14px]  spro:text-[16px] font-[600] text-white">LLEGÓ LA SEMANA </p>
    //         <p className="se:text-[14px] spro:text-[16px] font-[600] text-white mb-3">QUE ESTABAS ESPERANDO</p>
    //         </div>
    //         <img className="se:mb-3 spro:mb-4 se:w-[55%] spro:w-[58%]" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1700232461/WALLET%20BLACK/Slider/Group_10_n3pybp.svg"/>
    //         <div className="flex w-[80%] spro:w-auto flex-col items-center">
    //         <p className="text-[14px] font-[500] text-white">Descubrí todos los beneficios exclusivos </p>
    //         <p className="text-[14px] font-[500] text-white mb-2">que tenemos para vos.</p>
    //         </div>
    //         <Link to='/beneficios' className="container-button flex justify-center se:w-[40%] spro:w-[35%]">Conocé más</Link>
    //        </div>
    //         <img className="absolute top-[-0%] left-[0%] w-[22%]" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1700234491/WALLET%20BLACK/Slider/Group_11_ipbydj.svg" />
    //         <img className="absolute bottom-[0%] se:right-[-11%] spro:right-[-7.5%] w-[35%]" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1700233716/WALLET%20BLACK/Slider/Group_568_gc73gf.svg" />

    // </div> :

    // <div className="h-[80vh] slide4 w-full flex flex-row justify-center relative items-center  bg-black">
    //        <div className="flex flex-col justify-center items-center">
    //         <p className="text-[#FFFFFF] font-[300] xs:text-[16px] lt:text-[18px] mb-2">17 AL 24 DE NOVIEMBRE</p>
    //         <p className="xs:text-[17px] lt:text-[20px] font-[500] text-white mb-4">LLEGÓ LA SEMANA QUE ESTABAS ESPERANDO</p>
    //         <img className="xs:w-[55%] lt:w-auto mb-4" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1700232461/WALLET%20BLACK/Slider/Group_10_n3pybp.svg"/>
    //         <p className="text-white xs:text-[18px] md:text-[20px] font-[500] mb-4">Descubrí todos los beneficios exclusivos que tenemos para vos.</p>
    //         <Link to='/beneficios' className="container-button flex justify-center xs:w-[30%] md:w-[25%]">Conocé más</Link>
    //        </div>
    //         <img className="absolute xs:top-[-5%] xs:left-[13%] md:top-[-5.5%] lt:left-[20%] xs:w-[14%] lt:w-[12%]  md:w-[9%]" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1700233378/WALLET%20BLACK/Slider/Group_567_vw6xrw.svg" />
    //         <img className="absolute xs:top-[30%] xs:right-[13%] md:top-[37%] lt:right-[17%] xs:w-[15%] lt:w-[13%] md:w-[10%]" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1700233716/WALLET%20BLACK/Slider/Group_568_gc73gf.svg" />
    // </div> }

    // </>,

    <div className="bloque-hero-home height-bloque-hero-home">
      <img
        className="logo-black-scroll"
        alt="logo black"
        src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1672685150/WALLET%20BLACK/Recurso_3_1_juinny.svg"
        loading="lazy"
        decoding="async"
        width={350}
        height={83}
      />
      <p className="scroll-text-sub">Ahora sí podés.</p>
      {!vistaMobile ? (
        <video
          poster="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1678199199/WALLET%20BLACK/Videos/Posters/web_home_version_2_lfdj0d.png"
          muted
          loop
          autoPlay
          src="https://res.cloudinary.com/dc1bgmjr9/video/upload/q_50/v1678193792/WALLET%20BLACK/Videos/web_home_version_2_1_gpimik.mp4"
          style={{
            backgroundColor: "#000",
            position: "absolute",
            bottom: "0",
            left: "0",
            top: "0",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: "-999",
          }}
        ></video>
      ) : (
          <div
            className={"video-container-background-home"}
            style={{
              backgroundColor: "#000",
              position: "absolute",
              bottom: "0",
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
              zIndex: "-999",
            }}
          >
            <LazyVideo
              src="https://res.cloudinary.com/dc1bgmjr9/video/upload/q_25/v1678197520/WALLET%20BLACK/Videos/web_home_mobile_version_2_muumvn.mp4"
              type="video/mp4"
              poster="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1678197652/WALLET%20BLACK/Videos/Posters/web_home_mobile_version_2_1_sveod3.png"
              width={640}
              height={360}
            />
          </div>
      )}
      {/* <Timer date="2023-03-03 12:30:00" /> */}
      <div className="scroll-seccion-botones">
        <a
          id="playstore"
          target="_blank"
          rel="noreferrer"
          href="https://play.google.com/store/apps/details?id=app.blackwallet.ar"
        >
          <button id="playstore">
            <img
              style={{ height: "35px", objectFit: "contain" }}
              alt="google play"
              src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672685993/WALLET%20BLACK/play_store-01_wdahsu.svg"
              loading="lazy"
              decoding="async"
              width={40}
              height={35}
            />
            <div>
              <p className="button-scroll-sub">Descargala en</p>
              <p style={{ fontSize: "22px" }} className="button-scroll-titulo">
                Google Play
              </p>
            </div>
          </button>
        </a>
        <a
          id="appstore"
          target="_blank"
          rel="noreferrer"
          href="https://apps.apple.com/us/app/black-wallet/id1661314614"
        >
          <button id="appstore">
            <img
              alt="apple store"
              src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1672744949/WALLET%20BLACK/apple-logo-01-01_el9iip.svg"
              loading="lazy"
              decoding="async"
              width={40}
              height={43}
            />
            <div>
              <p className="button-scroll-sub">Descargala en</p>
              <p className="button-scroll-titulo">App Store</p>
            </div>
          </button>
        </a>
      </div>
    </div>,

    // <> NAVIBLACKK
    // {vistaMobile ?
    // <div className="height-bloque-hero-home  bgAngularM w-full relative flex flex-row justify-center pt-[2%]">
    // <div className="xs:w-[30%] lt:w-[25%]  flex flex-col relative items-center justify-center">
    //     <img className="mb-6 se:w-[75%] spro:w-[78%]"  src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1703259824/WALLET%20BLACK/Slider/naviBlack_xlkrbh.svg'/>
    //     <a href='/beneficios' className="container-button flex justify-center z-[1000]  ">
    //      Ver Beneficios
    //     </a>
    //   </div>
    //   <img className="absolute se:scale-[3.4] spro:scale-[3.5] w-[100%] se:bottom-[-40%] spro:bottom-[-25%] right-[-30%]"  src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1703261716/WALLET%20BLACK/Slider/navidad-venus_1_njl5hl.png"/>
    //   <img className="absolute se:w-[40%] spro:w-[50%] se:bottom-[-2%] spro:bottom-0 left-0 "  src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1703261564/WALLET%20BLACK/Slider/Group_575_gqzgdc.png"/>
    // </div>

    // :
    // <div className="h-[80vh] bgAngular w-full relative flex flex-row justify-center pt-[11%] ">
    //   <div className="xs:w-[30%] lt:w-[25%]  flex flex-col relative items-center justify-center">
    //     <img className="mb-6"  src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1703259824/WALLET%20BLACK/Slider/naviBlack_xlkrbh.svg'/>
    //     <a href='/beneficios' className="container-button flex justify-center z-[1000] w-[60%] md:w-[35%]">
    //      Ver Beneficios
    //     </a>
    //   </div>
    //   <img className="absolute xs:scale-[2.4] sm:scale-[2] lt:scale-[1.9] xs:bottom-[-45%] sm:bottom-[-75%] lt:bottom-[-90%]  md:bottom-[-75%] right-[-28%]"  src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1703261716/WALLET%20BLACK/Slider/navidad-venus_1_njl5hl.png"/>
    //   <img className="absolute xs:w-[35%] sm:w-[30%] lt:w-auto bottom-[-3%] xs:left-[5%] lt:left-[10%] md:left-[15%]"  src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1703261564/WALLET%20BLACK/Slider/Group_575_gqzgdc.png"/>

    // </div>
    // }
    //      </>,
    //SLIDE 2 --------------------------------------------------------------------
    <>
      {vistaMobile ? (
        <>
          <div className="height-bloque-hero-home w-full flex flex-col items-center relative bg-black">
            <div className="w-full flex  mt-2 pl-[8%]">
              <div className="flex flex-col w-[70%]  ">
                <h1 className="font-bold se:text-[1.7rem] spro:text-[2.2rem] mb-4 text-white">
                  La oportunidad de crecer para tu negocio
                </h1>
                <h3 className="text-[#FFFFFF] md:text-[1.1rem] z-[100] mb-4 font-[500]">
                  Queremos que conozcas todo lo que tenemos para ofrecerte, abrí
                  tu cuenta en Black.
                </h3>
                <a href="/comerciantes">
                  <button className="container-button w-[70%]">
                    Ir a comerciantes
                  </button>
                </a>
              </div>
            </div>

            <img
              src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_25/f_auto/v1693231498/WALLET%20BLACK/Slider/Comerciante_con_txt_1_zbcpel.svg"
              className="se:w-[55%] spro:w-[60%] bottom-0 right-[-4%] absolute  lt:w-[60%]"
              alt="cobra"
              loading="lazy"
              decoding="async"
              width={194}
              height={160}
            />
          </div>
        </>
      ) : (
        <>
          <div className="h-[80vh] w-full flex flex-row justify-center items-center  bg-black">
            <div className="w-[50%] flex justify-end pr-[4%]">
              <div className="flex flex-col w-[60%] lt:w-[50%] ">
                <h1 className="font-bold text-[1.5rem] xs:text-[2.2em] lt:text-[2.6rem]  md:text-[3.2rem] mb-4 text-white">
                  La oportunidad de crecer para tu negocio
                </h1>
                <h3 className="text-[#FFFFFF] md:text-[1.1rem] mb-4 font-[500]">
                  Queremos que conozcas todo lo que tenemos para ofrecerte, abrí
                  tu cuenta en Black.
                </h3>
                <a
                  href="/comerciantes"
                  className="container-button flex justify-center w-[60%] md:w-[45%]"
                >
                  Ir a comerciantes
                </a>
              </div>
            </div>

            <div className="w-[50%]">
              <img
                src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1693231498/WALLET%20BLACK/Slider/Comerciante_con_txt_1_zbcpel.svg"
                className="w-[70%] lt:w-[60%]"
                alt="cobra"
                loading="lazy"
                decoding="async"
                width={570}
                height={471}
              />
            </div>
          </div>
        </>
      )}
    </>,

    ////SLIDE 3-------------------------------------------------------------------------------------------
    <>
      {vistaMobile ? (
        <div className="height-bloque-hero-home w-full justify-center items-center relative  bg-black">
          {/* <div className="w-[50%] border-2 flex relative justify-end">
            <img className="absolute bottom-[-15%] z-[10] left-[-10%] border-2" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693235957/WALLET%20BLACK/Slider/quesito_con_verdeo_wya3q8.svg" />
            <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693235960/WALLET%20BLACK/Slider/papitas_png_jgcycy.svg' 
            className="lt:w-[70%] z-[10]  " alt='cobra'/>
            <img className="left-0 absolute z-[0]" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693234973/WALLET%20BLACK/Slider/Recurso_3_4_1_yvzwmj.svg"/>
            <img className="bottom-0 left-0 absolute z-[0]" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693234973/WALLET%20BLACK/Slider/Recurso_3_4_1_yvzwmj.svg"/>
            <img className="left-0 top-[35%] absolute z-[0]" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693234973/WALLET%20BLACK/Slider/Recurso_3_4_1_yvzwmj.svg"/>
          </div> */}
          <div className="w-full h-full  flex relative ">
            <img
              className="se:mb-3 spro:mb-0"
              src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_25/f_auto/v1693247233/WALLET%20BLACK/Slider/Group_508_2_boyynx.png"
              alt="bonus"
              loading="lazy"
              decoding="async"
              width={537}
              height={762}
            />
          </div>
          <div className="w-[100%] absolute  se:bottom-[7%] spro:bottom-[8%] flex justify-end pr-[4%]">
            <div className="flex flex-col w-[60%] lt:w-[50%] ">
              <h1 className="font-bold text-[1.5rem] xs:text-[2.2em] lt:text-[2.6rem]  md:text-[3.2rem] se:mb-3 spro:mb-4 text-white">
                Bonus Black
              </h1>
              <h3 className="text-[#FFFFFF] md:text-[1.1rem] se:mb-3 spro:mb-4 font-[500]">
                Ser Blacker tiene beneficios exclusivos y descuentos en locales
                adheridos.
              </h3>
              <a
                href="/beneficios"
                className="container-button w-[80%] flex justify-center md:w-[45%]"
              >
                Ver beneficios
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-[80vh] w-full flex flex-row justify-center items-center  bg-black">
          {/* <div className="w-[50%] border-2 flex relative justify-end">
         <img className="absolute bottom-[-15%] z-[10] left-[-10%] border-2" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693235957/WALLET%20BLACK/Slider/quesito_con_verdeo_wya3q8.svg" />
         <img src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693235960/WALLET%20BLACK/Slider/papitas_png_jgcycy.svg' 
         className="lt:w-[70%] z-[10]  " alt='cobra'/>
         <img className="left-0 absolute z-[0]" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693234973/WALLET%20BLACK/Slider/Recurso_3_4_1_yvzwmj.svg"/>
         <img className="bottom-0 left-0 absolute z-[0]" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693234973/WALLET%20BLACK/Slider/Recurso_3_4_1_yvzwmj.svg"/>
         <img className="left-0 top-[35%] absolute z-[0]" src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693234973/WALLET%20BLACK/Slider/Recurso_3_4_1_yvzwmj.svg"/>
       </div> */}
          <div className="w-[50%]  flex relative justify-end">
            <img
              alt="bonus"
              src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_50/f_auto/v1693243515/WALLET%20BLACK/Slider/Group_508_uiobly.png"
              loading="lazy"
              decoding="async"
              width={950}
              height={533}
            />
          </div>
          <div className="w-[50%] flex justify-center pr-[4%]">
            <div className="flex flex-col w-[60%] lt:w-[50%] ">
              <h1 className="font-bold text-[1.5rem] xs:text-[2.2em] lt:text-[2.6rem]  md:text-[3.2rem] mb-4 text-white">
                Bonus Black
              </h1>
              <h3 className="text-[#FFFFFF] md:text-[1.1rem] mb-4 font-[500]">
                Ser Blacker tiene beneficios exclusivos y descuentos en locales
                adheridos.
              </h3>
              <a
                href="/beneficios"
                className="container-button flex justify-center w-[60%] md:w-[45%]"
              >
                Ver beneficios
              </a>
            </div>
          </div>
        </div>
      )}
    </>,

    //SLIDE 4 ---------------------------------------------------------------------------------------------------------------------------------
    //   <>
    //   {vistaMobile ?  <div className="height-bloque-hero-home slide4 w-full flex flex-col  bg-black">
    //         <div className="w-full flex pt-[0%] pl-[4%]">
    //             <div className="flex flex-col w-[70%] lt:w-[50%] ">
    //                 <h1 className='font-bold se:text-[1.7rem] spro:text-[2.2rem] se:mb-3 spro:mb-4 text-white'>Black Social Club</h1>
    //                 <h3 className="text-[#FFFFFF] md:text-[1.1rem] se:mb-3 spro:mb-4 font-[500]">Vos alentá, nosotros te bancamos.</h3>
    //                 <a href='/beneficios' className="container-button  z-[100] flex justify-center se:w-[85%] spro:w-[80%]">
    //                  Sumate a la comunidad
    //                 </a>
    //             </div>
    //          </div>

    //          <div className="w-[50%]  flex justify-start">
    //           <img className="se:w-[50%] se:bottom-[-3%] spro:bottom-0 right-0 absolute"  src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693244616/WALLET%20BLACK/Slider/collage_todos_los_clubes-02_1_o4rtz8.svg'/>
    //         </div>

    // </div> :

    //   <div className="h-[80vh] slide4 w-full flex flex-row justify-center  items-center  bg-black">
    //         <div className="w-[50%] flex justify-end pr-[4%]">
    //             <div className="flex flex-col w-[60%] lt:w-[50%] ">
    //                 <h1 className='font-bold text-[1.5rem] xs:text-[2.2em] lt:text-[2.6rem]  md:text-[3.2rem] mb-4 text-white'>Black Social Club</h1>
    //                 <h3 className="text-[#FFFFFF] md:text-[1.1rem] mb-4 font-[500]">Vos alentá, nosotros te bancamos.</h3>
    //                 <a href='/beneficios' className="container-button flex justify-center w-[60%] xs:w-[75%] lt:w-[70%] md:w-[70%]">
    //                  Sumate a la comunidad
    //                 </a>
    //             </div>
    //          </div>

    //          <div className="w-[50%]  flex justify-start">
    //           <img className=""  src='https://res.cloudinary.com/dc1bgmjr9/image/upload/v1693244616/WALLET%20BLACK/Slider/collage_todos_los_clubes-02_1_o4rtz8.svg'/>
    //         </div>

    // </div> }

    // </>,

    //SLIDE 5 ---------------------------------------------------------------------------------------------------------------------------------
  ];

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm({
    mode: "onChange",
  });

  const handleClose = () => {
    setShow(false);
    reset();
  };

  const [showEnviado, setShowEnviado] = useState(false);
  const handleCloseEnviado = () => {
    setShowEnviado(false);
    reset();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmit = (data) => {
    console.log((data = { ...data, image: image }));
    axios
      .post(
        `${baseUrl}/emailSender/reporte`,
        (data = { ...data, image: image })
      )
      .then(function (response) {
        console.log(response.data);
        setShow(false);
        setShowEnviado(true);
        reset();
      })
      .catch(function (error) {
        console.log(error);
        setShowEnviado(false);
      });
  };

  return (
    <section className="home-hero">
      <div className="w-full flex flex-row justify-center">
        <Carousel
          className="w-full z-0"
          swipeable={true}
          responsive={responsive}
          showDots={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          customDot={<CustomDot />}
          infinite={true}
        >
          {
            carouselItems.map((item, index) => {
              return <article key={index}>{item}</article>
            })
          }
        </Carousel>
      </div>

      <div className="flex flex-col items-center space-y-3 fixed z-[200] right-[5%] bottom-[5%] xs:bottom-[10%]">
          <a
            id="playstore"
            href={
              vistaMobile
                ? "https://plataforma.blackwallet.com.ar/landing/share.html"
                : "https://play.google.com/store/apps/details?id=app.blackwallet.ar&hl=es_419"
            }
            target="blank"
          >
            <img
              onMouseEnter={vistaMobile ? null : () => setGplay(true)}
              onMouseLeave={vistaMobile ? null : () => setGplay(false)}
              src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1694461315/WALLET%20BLACK/gplay_prxmh8.svg"
              className="w-10"
              alt="Google Play"
              loading="lazy"
              decoding="async"
              width={40}
              height={40}
            />
          </a>
        {gplay && (
          <div className="fixed z-[200] text-[14px] bg-white right-[8.5%] bottom-[10%] xs:bottom-[27.5%] md:bottom-[23.5%] p-[0.5rem] rounded-md">
            Descargá la app
          </div>
        )}

          <a
            id="appstore"
            href={
              vistaMobile
                ? "https://plataforma.blackwallet.com.ar/landing/share.html"
                : "https://apps.apple.com/ph/app/black-wallet/id1661314614?uo=2"
            }
            target="blank"
          >
            <img
              onMouseEnter={vistaMobile ? null : () => setStore(true)}
              onMouseLeave={vistaMobile ? null : () => setStore(false)}
              src="https://res.cloudinary.com/dc1bgmjr9/image/upload/q_75/f_auto/v1694461315/WALLET%20BLACK/appstore_v1o51r.svg"
              className="w-12"
              alt="Apple Store"
              loading="lazy"
              decoding="async"
              width={48}
              height={48}
            />
          </a>
        {store && (
          <div className="fixed z-[200] text-[14px] bg-white right-[8.5%] bottom-[7%] xs:bottom-[20%] md:bottom-[17.5%] p-[0.5rem] rounded-md">
            Descargá la app
          </div>
        )}

        <button onClick={handleShow}>
          <img
            src={bichitos}
            alt="bichito"
            onMouseEnter={() => setBichito(true)}
            onMouseLeave={() => setBichito(false)}
            className="z-[200] w-10 xs:w-14"
            loading="lazy"
            decoding="async"
            width={56}
            height={56}
          />
          {bichito && (
            <div className="fixed z-[200] text-[14px] bg-white right-[8.5%] bottom-[10%] xs:bottom-[10.8%] p-[0.7rem] rounded-md">
              Reportá un error en la app
            </div>
          )}
        </button>
      </div>

      {/* POPUP REPORTAR ERROR */}
      <Modal
        show={show}
        backdrop="static"
        onHide={handleClose}
        size="lg"
        centered
        closeButton
        className="form-step "
      >
        <Modal.Body id="modal_reporte">
          <div>
            <button className="boton-cerrar z-[200]" onClick={handleClose}>
              x
            </button>
            <h1 className="tittle-web font-bold se:text-[1.6em]  xs:text-[2.5em]">
              Reportá un error
            </h1>
            <p className="text-web">
              Completá el formulario para que podamos ayudarte
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={
                vistaMobile ? "container-col-form-step" : "container-form-step"
              }
            >
              <div>
                <Controller
                  name="Mail"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      locale={locale}
                      inputRef={ref}
                      className="input-login"
                      autoComplete="no"
                      {...register("Mail", {
                        required: true,
                      })}
                      id="outlined-basic"
                      label="Mail"
                      variant="outlined"
                    />
                  )}
                />
                {errors.Mail && <p className="error">Ingrese su email</p>}
              </div>
            </div>

            <div className="container-form-step">
              <div>
                <Controller
                  name="dispositivo"
                  control={control}
                  //rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      locale={locale}
                      inputRef={ref}
                      className="input-login"
                      autoComplete="no"
                      {...register("dispositivo", {
                        required: true,
                      })}
                      id="outlined-basic"
                      label="Modelo de tu dispositivo"
                      variant="outlined"
                    />
                  )}
                />
                {errors.dispositivo && (
                  <p className="error">Ingrese el modelo de su dispositivo</p>
                )}
              </div>
            </div>

            <div className="container-form-step">
              <div>
                <Controller
                  name="version"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      locale={locale}
                      inputRef={ref}
                      className="input-login"
                      autoComplete="no"
                      {...register("version", {
                        required: true,
                      })}
                      id="outlined-basic"
                      label={"Versión de app"}
                      variant="outlined"
                    />
                  )}
                />
                {errors.version && (
                  <p className="error">Ingrese la versión de app</p>
                )}
              </div>

              <div className="container-form-step">
                <div>
                  <Controller
                    name="sistema"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { ref, ...field } }) => (
                      <select
                        {...field}
                        locale={locale}
                        className="input-login p-[1.1rem] rounded-[0.60rem] border w-full bg-black"
                        autoComplete="no"
                        {...register("sistema", {
                          required: true,
                        })}
                        id="outlined-basic"
                        label="Sistema operativo"
                        variant="outlined"
                        defaultValue={""}
                      >
                        <option
                          className="text-gray-400"
                          disabled={true}
                          value=""
                        >
                          Seleccioná un sistema operativo
                        </option>
                        <option value="Android">Android</option>
                        <option value="iOS">iOS</option>
                      </select>
                    )}
                  />
                  {errors.sistema && (
                    <p className="error">Seleccione un sistema operativo</p>
                  )}
                </div>
              </div>
            </div>

            <div className="container-form-step  flex flex-col relative">
              <input
                name="file"
                id="file"
                className="inputfile"
                type="file"
                onChange={handleImageChange}
              />
              <label
                htmlFor="fileInput"
                className="custom-file-label rounded-xl"
              >
                Arrastrá o buscá capturas de pantalla del error
              </label>
              <h5 className="w-full items-start text-white pl-4 text-[9px]">
                Imágenes (.jpg o .png) de hasta 5MB
              </h5>
            </div>

            {image ? (
              <div className="flex flex-row">
                <div className="w-[20%] border-2 h-[8vh]">
                  <img className="w-full object-contain h-full" src={image} alt="Bug screenshot" />
                </div>
                <button
                  className="text-white font-ubuntu"
                  onClick={() => setImage("")}
                >
                  X
                </button>
              </div>
            ) : (
              ""
            )}

            <div className="container-form-step">
              <div>
                <Controller
                  name="notas"
                  control={control}
                  //rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <input
                      {...field}
                      locale={locale}
                      inputRef={ref}
                      placeholder="Contanos más sobre el error"
                      className="input-login pb-[3rem] pl-[1rem] pt-2 rounded-[0.60rem] border w-full bg-black"
                      autoComplete="no"
                      {...register("notas", {
                        required: true,
                      })}
                      id="outlined-basic"
                      label="Notas o aclaraciones"
                      variant="outlined"
                    />
                  )}
                />
                {errors.notas && (
                  <p className="error">Ingrese una nota o aclaracion</p>
                )}
              </div>
            </div>

            <div className="container-button-step">
              <Button className="container-button" type="submit">
                Enviar
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEnviado}
        backdrop="static"
        onHide={handleCloseEnviado}
        size="lg"
        centered
        closeButton
        className="form-step"
      >
        <Modal.Body className="modal_reporte" id="Reporte_enviado">
          <div className="check-form-enviado" id="Reporte_enviado">
            <button className="boton-cerrar" onClick={handleCloseEnviado}>
              x
            </button>
            <img
              width={70}
              src="https://res.cloudinary.com/dc1bgmjr9/image/upload/v1674834976/WALLET%20BLACK/check-enviado_io9ejl.png"
              alt="Check"
            />
            <h1
              id="Reporte_enviado"
              className="reporte_enviado tittle-web se:text-[2em] xs:text-[2.5em]"
            >
              Reportaste el error
            </h1>
            <p className="text-web w-[80%] xs:w-[40%]">
              Vamos a revisar la informacion y buscar una solucion.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Hero;
